import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "./layout"

export default function PageTemplate({ data: { mdx } }) {
  return (
    <Layout>
      <article class="markdown">
        <div className="px-8 py-10 md:px-24 md:py-8">
          <div className="md:max-w-3xl md:mx-auto">
            <h1>{mdx.frontmatter.title}</h1>
            <div>
              <audio 
                src={mdx.frontmatter.url}
                autoPlay={false}
                controls={true}
                preload="none"
              />
            </div>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PodcastEpisodeQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        url
      }
    }
  }
`